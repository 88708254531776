.subscription-container {
    background-color: #E9E9E9; 
    padding: 40px 0;
    background-image: url("../assets/background/subscription-bg.png");
    background-repeat: no-repeat;
    background-position: center;
  }
  .subscription-container h2 {
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  .subscription-container p {
    font-size: 1.1rem;
    margin-bottom: 30px;
  }
  .subscription-form .form-control {
    border-radius: 0;
    height: 50px;
    font-size: 1rem;
  }
  .subscription-form textarea.form-control {
    height: auto;
    resize: none;
  }
  .btn-danger {
    background-color: #b10a2f;
    border-color: #b10a2f;
    padding: 10px 30px;
    font-size: 1rem;
    border-radius: 0;
  }
  