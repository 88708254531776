.blog-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-img-top {
    height: 250px;
    object-fit: cover;
}

.card-title {
    font-size: 1.25rem;
    margin-bottom: 15px;
    text-align: center;
    flex-grow: 1;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.btn-primary {
    background-color: #CB1517;
    border-color: #CB1517;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
}

.btn-primary:hover {
    background-color: #a70d0f;
    border-color: #a70d0f;
    transition: all 0.3s ease-in-out;
}

.card {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    height: 100%; /* Make sure the card takes the full height */
}

.card-body {
    padding: 15px;
    flex-grow: 1;
}
.active>.page-link, .page-link.active {
    z-index: 3;
    color: #fff;
    background-color: #CB1517;
    border-color: #CB1517;
}
.page-link {
    color: #CB1517;
}
.read-more-news {
border: 1px solid #CB1517;
color: black;
width: 100%;
text-align: center;
border-radius: 3px;
}
.read-more-news:hover {
    background-color: #CB1517;
    color: #fff !important;
    transition: all 0.3s ease-in-out;
}