.contact-page {
    margin-top: 20px;
  }
  
  .breadcrumb {
    background-color: transparent;
    padding: 0;
    margin-bottom: 20px;
    font-size: 0.9rem;
  }
  
  .breadcrumb a {
    text-decoration: none;
    color: #007bff;
  }
  
  .breadcrumb .active {
    color: #6c757d;
  }
  
  .contact-card {
    background-color: #f8f9fa;
    border: none;
  }
  
  .contact-card-body {
    padding: 1.5rem;
  }
  
  .contact-card-title {
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .contact-table td {
    padding: 0.75rem;
    vertical-align: top;
  }
  
  .contact-table .font-weight-bold {
    font-weight: 700;
  }
  
  .map-container {
    margin-top: 20px;
  }
  
  .form-label {
    font-weight: bold;
    width: 100%;
  }
  .btn-contact {
    background-color: #b10a2f;
    border-color: #b10a2f;
    border-radius: 25px;
  }