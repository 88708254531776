.blog-detail-container {
    margin-top: 40px;
  }
  
  .breadcrumb {
    background-color: transparent;
    padding: 0;
    margin-bottom: 20px;
    font-size: 0.9rem;
  }
  
  .breadcrumb a {
    text-decoration: none;
    color: #007bff;
  }
  
  .breadcrumb .active {
    color: #6c757d;
  }
  
  h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .text-muted {
    color: #6c757d;
    margin-bottom: 20px;
  }
  
  .blog-image-container {
    width: 100%;
    max-height: 600px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .blog-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .most-read-container {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .most-read-item {
    display: flex;
    align-items: center;
  }
  
  .most-read-item img {
    width: 100px;
    height: 100px;
    margin-right: 15px;
    object-fit: cover;
  }
  
  .most-read-item h6 {
    font-size: 1rem;
    font-weight: bold;
  }
  
  .most-read-item p {
    font-size: 0.9rem;
    color: #6c757d;
  }
  