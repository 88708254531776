.btn-outline-primary {
  border: none;
  color: #343a40;
  background-color: transparent;
  font-size: 24px;
}

.btn-outline-primary:hover {
  color: #CB1517;
  background-color: transparent;
}

.btn-outline-primary:active {
  color: #CB1517;
}

.navbar-nav .nav-link {
  font-weight: 500;
  font-size: 15px;
}

.offcanvas-body {
  padding: 20px;
  text-align: left;
  background-color: #F8F9FA;
  overflow: hidden;
}

.offcanvas-body h6 {
  font-size: 18px;
  margin-bottom: 15px;
  text-align: start;
}

.offcanvas-body .nav-link {
  color: #343a40;
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.offcanvas-top {
  position: absolute;
  top: 110px !important;
}

.offcanvas-backdrop.show {
  opacity: 0.2;
}

.navbar-toggler {
  margin: 10px;
}

.offcanvas-header {
  background-color: #F8F9FA;
}

.font-awesome-icon-menu {
  font-size: 24px;
  color: #343a40;
  margin-right: 10px;
  margin-left: 0px;
}

.whatsapp-icon {
  width: 35% !important;
  height: auto !important;
}

.social-icons {
  display: inline-flex;
  gap: 10px;
}
.nav-link img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.navbar-nav .dropdown-toggle {
  display: flex;
  align-items: center;
}

.dropdown-menu img {
  width: 20px;
  height: 20px;
}

.whatsapp-icon {
  width: 30px;
  height: 30px;
}

.font-awesome-icon-menu {
  font-size: 20px;
  margin-right: 10px;
}
