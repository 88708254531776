.subscribe-container {
    max-width: 700px;
  }
  
  .subscribe-divider {
    border-top: 3px solid #d32f2f;
    width: 10%;
    margin-left: 0;
  }
  
  .subscribe-icon {
    color: #e53935;
  }
  
  .subscribe-title {
    font-weight: bold;
  }
  
  .subscribe-description {
    font-size: 16px;
    color: #666;
  }
  
  .subscribe-checkbox {
    text-align: left;
  }
  
  button.subscribe-button {
    background-color: #e53935;
    border: none;
    padding: 10px 30px;
    font-size: 16px;
    cursor: pointer;
  }
  
  button.subscribe-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  button.subscribe-button:hover:not(:disabled) {
    background-color: #d32f2f;
  }
  