.featured-videos {
    margin-top: 20px;
  }
  
  .video-slide {
    padding: 10px;
  }
  
  .video-slide iframe {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .youtube-section {
    margin-top: 20px;
  }
  
  .youtube-button {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  
  .card-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
  }
  #controlled-tab-example  {
    text-align: center;
    justify-content:start;
    align-items: center;  
  }
  #controlled-tab-example button  {
    color: #000;
    font-weight: 600;
    text-align: center;
    justify-content:start;
    align-items: center;
  }
  .text-bold {
    font-weight: 700;
  }