.footer {
    background-color: #f8f9fa;
    padding: 40px 0;
    color: #343a40;
    font-size: 14px;
    background-image:url("../assets//background/footer-bg.png"); 
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
}

.footer h5 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.footer h6 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
}

.footer p {
    margin-bottom: 10px;
}

.footer ul {
    list-style: none;
    padding: 0;
}

.footer ul li {
    margin-bottom: 10px;
}

.footer ul li a {
    color: #343a40;
    text-decoration: none;
}

.footer ul li a:hover {
    text-decoration: underline;
}

.social-icons a {
    margin-right: 15px;
    font-size: 18px;
    color: #343a40;
}

.social-icons a:hover {
    color: #CB1517;
}

.footer-bottom {
    background-color: #CB1517;
    padding: 10px 0;
    text-align: center;
    color: #fff;
}

.footer-bottom p {
    margin: 0;
    font-size: 14px;
}
.footer-logo {
    width: 200px;
    height: auto;
    object-fit: cover;
}