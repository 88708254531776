.social-sidebar {
    position: fixed;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
  }
  
  .social-sidebar a {
    display: block;
    margin: 10px 0;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width 0.3s, height 0.3s; 
  }
  
  .social-icon {
    font-size: 24px;
    color: #000;
    transition: font-size 0.3s; 
  }
  
  .social-icon.instagram {
    color: #E1306C;
  }
  
  .social-icon.facebook {
    color: #1877F2;
  }
  
  .social-icon.whatsapp {
    color: #25D366;
  }
  
  .social-icon.email {
    color: #0072C6;
  }
  
  /* Küçük ekranlar için ikonları küçült */
  @media (max-width: 768px) {
    .social-sidebar a {
      width: 40px;
      height: 40px;
    }
  
    .social-icon {
      font-size: 20px;
    }
  }
  
  @media (max-width: 576px) {
    .social-sidebar a {
      width: 35px;
      height: 35px;
    }
  
    .social-icon {
      font-size: 18px;
    }
  }
  
  @media (max-width: 400px) {
    .social-sidebar a {
      width: 30px;
      height: 30px;
    }
  
    .social-icon {
      font-size: 16px;
    }
  }
  