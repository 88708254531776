.cookie-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #2e2e2e;
    color: white;
    text-align: center;
    padding: 20px 10px;
    z-index: 1000;
    font-size: 14px;
}

.cookie-consent-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
}

.cookie-consent-content p {
    flex: 1;
    margin: 0;
    padding-right: 20px;
    text-align: left;
}

.cookie-consent-buttons {
    display: flex;
    gap: 10px;
}

.cookie-consent button {
    background-color: #ff4c4c;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 3px;
    font-size: 14px;
}

.cookie-consent button:hover {
    background-color: #e04c4c;
}

.cookie-consent .btn-secondary {
    background-color: #4c4c4c;
}

.cookie-consent .btn-secondary:hover {
    background-color: #3c3c3c;
}

.modal {
    display: block;
    z-index: 1040; /* Ensures the modal background is beneath the modal content */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Modal backdrop */
}

.modal-dialog {
    max-width: 500px;
    margin: 10vh auto; /* Centers the modal vertically and horizontally */
}

.modal-content-cookie {
    background-color: #ffffff;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid transparent;
    max-width: 400px;
    text-align: center;
    border-radius: 8px;
    position: relative;
    z-index: 1050; /* Ensures modal content stays on top */
    pointer-events: auto; /* Ensure modal content is interactive */
}
