
  .card {
    transition: transform 0.2s;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  .blog-page-container {
    background-color: #ffffff;
    padding: 0px;
  }
