.hst-component {
    background-color: #fff;
    padding: 20px;
  }
  
  .carousel img {
    height: 400px;
    object-fit: cover;
  }
  
  .carousel-caption {
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
  }
  
  .side-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 35px !important;
  }
  
  .article-preview {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  
  .article-preview img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .pagination-row {
    margin-top: 20px;
  }
  
  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .pagination-item {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
  }
  .pagination-item:hover {
    background-color: #CB1517;
    color: #fff;
    transition: all 0.3s ease;
  }
  .pagination-item.active {
    background-color: #CB1517;
    color: #fff;
  }
  .carousel-control-next-icon, .carousel-control-prev-icon {
    background-color: #CB1517;
    color: #ffffff;
    border-radius: 100%;
    padding: 5px;
    opacity: 1;
  }