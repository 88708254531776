.scroll-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    background-color: #a70d0f;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.4s ease, transform 0.4s ease;
    z-index: 1000;
  }
  
  .scroll-to-top:hover {
    background-color: #CB1517;
  }
  
  .scroll-to-top.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  .scroll-to-top {
    transform: translateY(20px);
  }
  