.youtube-channel-container {
    margin-top: 40px;
  }
  
  .most-read-container {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .most-read-item {
    display: flex;
    align-items: center;
  }
  
  .most-read-item img {
    width: 100px;
    height: 100px;
    margin-right: 15px;
    object-fit: cover;
  }
  
  .most-read-item h6 {
    font-size: 1rem;
    font-weight: bold;
  }
  
  .most-read-item p {
    font-size: 0.9rem;
    color: #6c757d;
  }
  .read-more-most-read {
    color: #CB1517;
    text-decoration: none;
  }