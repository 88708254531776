.fikir-paneli-magazine-container {
    margin-top: 2rem;
  }
  
  .fikir-paneli-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 10px;
  }
  
  .fikir-paneli-card-img {
    border-radius: 10px 10px 0 0;
    height: 300px;
    object-fit: contain;
  }
  
  .fikir-paneli-card .card-body {
    padding: 1.5rem;
  }
  
  .fikir-paneli-card .card-title {
    font-weight: bold;
    color: #343a40;
  }
  
  .fikir-paneli-btn-download {
    background-color: #CB1517;
    border: none;
    color: #fff;
  }
  
  .fikir-paneli-btn-view {
    border: 1px solid #CB1517;
    color: #fff;
    background-color: #CB1517;
  }
  
  .fikir-paneli-btn-view:hover {
    background-color: #ffffff;
    color: #CB1517;
    border: 1px solid #CB1517;
  }
  
  .text-center {
    margin-bottom: 2rem;
  }
  
  .ml-2 {
    margin-left: 0.5rem;
  }
  