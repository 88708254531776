.header {
  color: white;
  padding: 10px 0;
}
.header-container {
  background-color: #a60000;
}

.logo img {
  width: 200px;
  height: auto;
  object-fit: contain;
}

.youtube-button {
  background-color: #ff0000;
  border: none;
}

.subscribe-link {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.weather {
  font-size: 18px;
}

.search-form input {
  border-radius: 0;
}

.search-form button {
  border-radius: 0;
  background-color: #333;
}

.tradingview-widgets {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #111;
  padding: 10px 0;
}

.tradingview-widget-container {
  flex: 1;
  min-width: 200px;
  max-width: 350px;
  height: 220px;
  margin: 10px;
}

@media (max-width: 768px) {
  .tradingview-widgets {
    flex-direction: column;
    align-items: center;
  }

  .tradingview-widget-container {
    width: 100%;
    max-width: none;
  }
}
.tradingview-widgets-container {
  background-color: #111;
  width: 100% !important;
}
.youtube-logo {
  max-width: 120px;
  object-fit: contain;
  height: auto;
}
.tradingview-crypto-widget-container {
  width: 100% !important;
  height: auto !important;
}
.search-results {
  margin-top: 20px;
}

.search-results ul {
  padding: 0;
  list-style: none;
}

.search-results .list-group-item {
  background-color: #f9f9f9;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
.clock-container-header {
  width: 100%;
  height: auto;
  background-color: #111111;
}