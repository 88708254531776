.news-dynamic-container {
    padding: 3rem 1rem;
    margin: 0 auto;
}

.news-dynamic-title {
    font-size: 2.5rem;
    color: #333333;
    margin-bottom: 1.5rem;
    text-align: left;
    font-weight: bold;
}

.news-dynamic-image {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(145, 52, 52, 0.1);
}

.news-dynamic-content p {
    font-size: 1.2rem;
    color: #555555;
    line-height: 1.8;
    margin-bottom: 1.5rem;
    text-align: left;
}

.most-read-container {
    padding-left: 1.5rem;
    border-left: 2px solid #cccccc;
}

.most-read-item img {
    margin-bottom: 0.5rem;
    border-radius: 8px;
}

.read-more-most-read {
    color: #CB1517;
    text-decoration: none;
}

.read-more-most-read:hover {
    text-decoration: underline;
}
