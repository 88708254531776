.flip-clock-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  background-color: #111111;
  max-width: 100%; /* Tüm genişliği kapsa */
  box-sizing: border-box;
}

.flip-clock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  color: white;
  flex: 1 1 10%; /* Esnek kutu, min 10%, max alanı kapla */
  min-width: 90px; /* Çok dar olmaması için minimum genişlik */
  max-width: 100px; /* Maksimum genişlik */
}

.flip-clock h3 {
  font-size: 14px;
  margin-bottom: 8px;
}

.flip-container {
  display: flex;
}

.flip-card {
  width: 40px;
  height: 60px;
  margin: 0 2px;
  perspective: 1000px;
}

.flip-front,
.flip-back {
  width: 100%;
  height: 100%;
  background-color: #272727;
  color: white;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  position: absolute;
  backface-visibility: hidden;
  transition: transform 0.6s ease;
}

.flip-front {
  transform: rotateX(0deg);
}

.flip-back {
  transform: rotateX(-180deg);
}

.flip-card:hover .flip-front {
  transform: rotateX(180deg);
}

.flip-card:hover .flip-back {
  transform: rotateX(0deg);
}
/* Flip sınıfı tetiklendiğinde flip animasyonunu uygula */
.flip-card.flip .flip-front {
  transform: rotateX(180deg);
}

.flip-card.flip .flip-back {
  transform: rotateX(0deg);
}
.colon {
  font-size: 30px; /* Saat ve dakika büyüklüğüne göre ayarla */
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}
/* Küçük ekranlar için medya sorguları */
@media (max-width: 1200px) {
  .flip-clock {
    flex: 1 1 18%; /* Küçük ekranlarda esneklik artırıldı */
  }

  .flip-card {
    width: 35px;
    height: 55px;
  }

  .flip-front,
  .flip-back {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .flip-clock {
    flex: 1 1 25%; /* Daha küçük ekranlarda daha fazla esneklik */
  }

  .flip-card {
    width: 30px;
    height: 50px;
  }

  .flip-front,
  .flip-back {
    font-size: 24px;
  }
}

@media (max-width: 576px) {
  .flip-clock {
    flex: 1 1 40%; /* En küçük ekranlarda */
  }

  .flip-card {
    width: 25px;
    height: 45px;
  }

  .flip-front,
  .flip-back {
    font-size: 20px;
  }
}
