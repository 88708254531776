.tradingview-widget-container {
  width: 100%; /* Tam genişlik */
  max-width: 100%;
  margin: 0 auto; /* Ortalamak için */
  background-color: #111111; /* Arka plan rengi (görseldeki gibi) */
  padding: 10px 0; /* Yukarıdan ve aşağıdan boşluk eklemek için */
}

.tradingview-widget-container__widget {
  width: 100%;
}

.tradingview-crypto-widget-container {
  margin: 0 auto;
  background-color: #111111;
  padding: 10px 0;
}
